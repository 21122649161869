.gradientColor1 {
  background-image: linear-gradient(
    to top right,
    #07ffc1d2 3%,
    #7acef5 91%
  ) !important;
}

.rpb-gradient {
  background-image: linear-gradient(
    to top right,
    #0066ff 3%,
    #ccffff 91%
  ) !important;
}


.gradientColor2 {
  background-image: linear-gradient(
    to top right,
    #93ff06d2 3%,
    #7acef5 91%
  ) !important;
}