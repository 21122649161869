@import "./static/scss/gradientColors";

$navbar-color: #cccccc;

//navbar start
.navbar-brand {
  color: #5c5d5c !important;
  font-size: 36px !important;
  margin: 0;
  padding: 0;
  line-height: 1 !important;
  font-weight: 200;
  letter-spacing: 3px;
}

.navbg {
  background-color: $navbar-color;
}
.round-element {
  border-radius: 50px !important;
}

.nav-link {
  color: #141414 !important;
  font-weight: 500;
  display: inline;

  &:hover {
    color: #ffffff !important;
    border-radius: 30px 30px 10px 10px !important;
    background-color: #296ec9c5 !important;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.226), 0 4px 15px 0 rgba(0, 0, 0, 0.219);
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fffffffa !important;
  background-color: #4a81caee !important;
  font-weight: 600;
  border-radius: 10px 10px 30px 30px;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.164), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
// navbar section ends

// Content section
.home-section {
  min-height: 770px;
  width: 100%;
  background-image: url(./img/bg.jpeg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 200px;
  justify-content: center;
  justify-items: center;

  &.container {
    min-height: 400px;
  }
}

.progress-bar-container {
  border-radius: 6px;
  margin: 8px;
  padding: 2px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.096),
    0 4px 15px 0 rgba(0, 0, 0, 0.075);
  
  img {
    height: 80px;
    width: 82px;
    padding: 5px;
  }
}

.intro-title {
  font-family: "Times New Roman", cursive;
  margin: 0;
  font-size: 55px;
  font-weight: 400;
  line-height: 50px;
  color: #222222;
}
.intro-description {
  font-family: 'Z003', cursive;
  font-size: larger;
}
.content-section {
  padding-top: 110px;
  padding-bottom: 70px;
  min-height: 900px;
}

.title-container {
  margin-bottom: 24px;
  margin-top: 10px;
  padding-bottom: 30px;
  justify-content: left;
}
.section-title {
  font-family: "Z003", cursive !important;
  font-size: 50px !important;
  font-weight: bold !important;
}

// card container section
.message {
  font-size: 25px;
  font-weight: 400;
  line-height: 1;
  padding-top: 16px;
}

.rounded-container {
  border-radius: 20px;
  padding: 10px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.192),
    0 4px 15px 0 rgba(0, 0, 0, 0.185);
}

.iframe-container {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.192),
    0 4px 15px 0 rgba(0, 0, 0, 0.185);
}

.round-icon-container {
  background: rgba(17, 78, 190, 0.877);
  border-radius: 50px;
  text-align: center;
  padding-top: 13px;
  margin-left: 100px;
  color: #fff;
  margin: 30px;
  width: 45px;
  height: 44px;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.24),
    0 4px 15px 0 rgba(0, 0, 0, 0.185);

  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.356),
      0 4px 15px 0 rgba(0, 0, 0, 0.281);
  }
  &.p {
    color: #000000 !important;
  }
}

.contact-details {
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  font-size: 19px;
  font-family: fantasy;
  font-weight: 400;
  text-align: center;
  color: rgb(245, 149, 132);
  background-color: #ffffff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.075),
    0 4px 15px 0 rgba(0, 0, 0, 0.116);
  
  &:hover {
    color: rgb(250, 98, 71);
  }
}

.rounded-container .head-container {
  padding: 1rem;
  margin-top: -40px;
  margin-bottom: 3rem;
  padding: 10px;
  color: #fff;
  text-align: center;
  font-size: 50px;
  width: 80%;
  border-radius: 0.545rem;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.377),
    0 4px 15px 0 rgba(0, 0, 0, 0.301);
  background: linear-gradient(to right, #2e2eb4 5%, #86b3e0 90%);
}
.me-container {
  border-radius: 0.445rem;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.247),
    0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
// card container section ends

// Scrollbar
::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #2363b6d3;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(250, 45, 45, 0.1);
  background-color: #497ec4d3;
}
